import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMedicineList(product_id) {
  const id = product_id;
  const type_Total = ref(0);
  const type_Data = ref([]);
  const category_Total = ref(0);
  const category_Data = ref([]);
  const group_Total = ref(0);
  const group_Data = ref([]);
  const warehouse_Total = ref(0);
  const warehouse_Data = ref([]);
  const stock_cycle_Data = ref([]);
  const unit_Total = ref(0);
  const unit_Data = ref([]);

  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'stock_date', label: 'รอบสต๊อก', sortable: false },
        { field: 'company_name', label: 'บริษัทผู้ผลิต', sortable: false },
        { field: 'exp_date', label: 'วันที่หมดอายุ', sortable: false },
        
        { field: 'price', label: 'ราคาทุน', sortable: false },
        { field: 'stock_in', label: 'โอนเข้า', sortable: false },
        { field: 'stock_out', label: 'โอนออก', sortable: false },
        { field: 'balance', label: 'คงเหลือ', sortable: false },
        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status,id], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-stock/fetchStockData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            product_id:id.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            order_by:'id DESC'
         
          })
          .then(response => {
          
            response.data.data.forEach((data) => data.balance = data.stock_in - data.stock_out);

            Data.value = response.data.data;

            console.log('medicines stock value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching stock' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
  
  const fetchWarehouseData = () => {
      //fetchDeleteProperty
      var data = {
        is_active:1,
       // branch_id:branch_id,
        order_by:'id DESC'
      }
      
      var res = store.dispatch('app-stock/fetchWarehouseData',data)
      res.then(response=>{
        warehouse_Data.value = response.data.data;
      }).catch(()=>{
        console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Warehouse' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const fetchStockcycleData = () => {

      let data = {
        is_active:1,
        branch_id:branch_id,
        order_by:'id DESC'
      }
      
      var res = store.dispatch('app-stock/fetchStockcycleData',data)
      res.then(response=>{
        
        stock_cycle_Data.value = response.data.data;
      }).catch(()=>{
        console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Warehouse' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
  fetchData();
  fetchWarehouseData();
  fetchStockcycleData();
  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-stock/deleteStockData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveMedicineVariant = status =>{
    if (status == 'ยา') return 'danger'
    else if (status == 'อุปกรณ์') return 'primary'
    return 'light'
  }
  const resolveMedicineGroupnameVariant = status =>{
    if (status == 'ยา') return 'HeartIcon'
    else if (status == 'อุปกรณ์') return 'SquareIcon'
    return 'light'
  }
    return {
        fetchData,
        fetchWarehouseData,
        warehouse_Data,
        fetchStockcycleData,
        stock_cycle_Data,
        tableColumns,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        id,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveMedicineVariant,
        resolveMedicineGroupnameVariant
    }
}