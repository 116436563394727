<template>
    <div>
    <stock-form
      :is-stock-form-sidebar-active.sync="isStockFormSidebarActive"
      :headertext="HeaderText"
      :stock-data="StockData"
      @refetch-data="updatedData" 
    />
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col lg="6" class="pt-1 pb-1">
                        <b-badge
                        pill
                        :variant="`light-${resolveMedicineVariant(Data.group_name)}`"
                        class="text-capitalize"
                    >
                    <feather-icon :icon="resolveMedicineGroupnameVariant(Data.group_name)" />
                    {{Data.title}}
                    </b-badge>
                    </b-col>
                    <b-col lg="6" class="pt-1 pb-1">
                        <div class="d-flex align-items-center justify-content-end">
                        <b-button 
                            @click="addMedicineStock"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-success">
                            <feather-icon icon="PlusIcon" />
                            นำเข้าคลัง
                        </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">กลุ่ม:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.group_name}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">รหัส:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.sku}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ชื่อ:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.title}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">หน่วยวัด:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.unit_name}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">หมวดหมู่:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.category_name}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ประเภท:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.type_name}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ราคาขาย:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.sell_price}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">สถานะ:</span>
                        <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(Data.is_active)}`"
                        class="text-capitalize"
                        >
                            {{ resolveUserStatusnameVariant(Data.is_active) }} 
                        </b-badge>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">สร้างเมื่อ:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.created_at}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">แก้ไขล่าสุด:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.updated_at}}</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
<script>
import { BBreadcrumb } from 'bootstrap-vue'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BBadge
 } from 'bootstrap-vue'
import StockForm from './StockForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import medicineStoreModule from '../medicineStoreModule'
import useMedicineStockList from './useMedicineStockList'

 import Ripple from 'vue-ripple-directive'
 import router from '@/router'

 export default {
    directives: {
        Ripple,
    },
    components:{
        BBreadcrumb,
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BBadge,
        ValidationProvider,
        ValidationObserver,
        StockForm
    },
    model: {
      prop: 'isStockFormSidebarActive',
      event: 'update:is-stock-form-sidebar-active',
    },
    setup({ emit }){
        const Data = ref({})
        const HeaderText = ref('')
        const isStockFormSidebarActive = ref(false)
        const StockData = ref({})
        const id = ref(router.currentRoute.params.id);
        const userStorage = JSON.parse(localStorage.getItem('userData'));

        const PATIENTS_STORE_MODULE_NAME = 'app-medicine';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, medicineStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });

        store.dispatch('app-medicine/fetchData', {id:id.value})
           .then((res) => {
                Data.value = res.data.data[0];
            });

        function updatedData () {
        this.$emit('refetch-data')
        };

        const resolveUserStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
        }
        const resolveUserStatusnameVariant = status => {
        
            if (status) return 'Active'
            if (!status) return 'Inactive'
            return 'primary'
        }
        const resolveMedicineVariant = status =>{
        if (status == 'ยา') return 'danger'
        else if (status == 'อุปกรณ์') return 'primary'
        return 'light'
        }
        const resolveMedicineGroupnameVariant = status =>{
        if (status == 'ยา') return 'HeartIcon'
        else if (status == 'อุปกรณ์') return 'SquareIcon'
        return 'light'
        }

        const addMedicineStock = (data)=>{
        
        StockData.value = {};
        HeaderText.value = 'นำเข้าคลัง';
        isStockFormSidebarActive.value = true;
        }
        
        return {
            updatedData,
            Data,
            StockData,
            HeaderText,
            isStockFormSidebarActive,
            addMedicineStock,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            resolveMedicineVariant,
            resolveMedicineGroupnameVariant

        }
        
    }
 }
</script>
